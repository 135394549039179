<template>
  <div class="picType">
    <el-form :model="ruleForm"  ref="ruleForm"  class="demo-ruleForm">
      <span style="margin-bottom: 10px;display: block">分类号</span>
      <el-form-item label="" prop="desc">
        <el-input  :rows="4" style="border: 1px solid #EBEEF5"  type="textarea" v-model="ruleForm.desc"></el-input>
      </el-form-item>
      <el-form-item >
        <el-button @click="picSure" style="float: right" size="small">确定</el-button>
        <el-button @click="picClear" style="float: right;margin-right: 20px" size="small" type="primary" >清空</el-button>
      </el-form-item>
    </el-form>
    <hr style="border-top: 1px solid #EBEEF5"/>
    <el-form ref="form" :inline="true" label-position="left" :model="form" label-width="110px">
      <el-form-item label="IPC分类查询">
        <el-radio-group v-model="form.resource">
          <el-radio :label="1">按分类号查询</el-radio>
          <el-radio :label="2">按内容查询</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-top: 4px" size="small " label="">
        <el-input  v-model="filterText"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="query" type="primary">查询</el-button>
      </el-form-item>
    </el-form>
    <el-tree
      class="tree"
      @check-change="handleCheckChange"
      :accordion="true"
      :check-strictly="true"
      v-loading = "pictLoading"
      element-loading-text = "数据正在加载中"
      :data="IPCdata"
      ref="tree"
      :auto-expand-parent="false"
      show-checkbox
      height="400px"
      :filter-node-method="filterNode"
      node-key="searchcode">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <el-tooltip popper-class="ipcTooltip"  effect="light" :content="node.data.name" placement="top-start">
        <span>{{node.data.searchcode}}:{{ node.data.name }}</span>
          </el-tooltip>
      </span>
    </el-tree>
  </div>
</template>

<script>
  import { exportFile, appNosOrPubNosExcelImport, adSearchLog, delSearchLog, ipcQuery } from "@/request/patentRequest";
    export default {
        name: "picType",
      props:[
        // "IPCdata",
        // "pictLoading",
        "advancedIpcClass",
        "dialogTableVisible"
      ],
      data(){
          return{
            pictLoading:false,
            IPCdata:[],
            ruleForm: {
              desc: ''
            },
            form: {
              name: '',
              region: '',
              date1: '',
              date2: '',
              delivery: false,
              type: [],
              resource: 1,
            },
            //树形结构过滤
            filterText: '',
            //获取选中的searchcode集合
            searchcodes:[],
          }
      },
      computed:{
          numberType(){
            return this.ruleForm.desc
          }
      },
      async mounted() {

        this.pictLoading = true
        let data = await this.$axios.get(`/patent/patentipc/searchPatentIpcs`);
        if (data.data.code == 0) {
          this.IPCdata = data.data.data
          this.pictLoading = false
          // this.dialogTableVisible = true
          this.$nextTick(() => {
            this.searchcodes = this.advancedIpcClass.split(' ')
            // if(that.advancedIpcClass != null) that.$refs.tree.setCheckedKeys(that.advancedIpcClass.split(' '));
            // this.ruleForm.desc = this.advancedIpcClass
            // this.searchcodes = []
          })
        }else {
          this.pictLoading = false
        }
      },
      methods:{
        //查询
        async queryResults(res){

          this.pictLoading = true
          let data = []
          if(res.filterText){
            data = await ipcQuery({
              keyword:res.filterText,
              searchType:res.form
            })

            if(data.code == 0){
              this.IPCdata = data.data
            }
          }else {
            data = await ipcQuery({
              keyword:'',
              searchType:''
            })

            if(data.code == 0){
              this.IPCdata = data.data
            }
          }
          this.pictLoading = false
        },
        //分类号失去光标
        // blur(){
        //   this.$refs.tree.setCheckedKeys(this.ruleForm.desc.split(' '));
        // },
        //pic确定
        picSure(){
          this.$emit('picSure',this.ruleForm.desc)

        },
        //pic清空
        picClear(){
          this.ruleForm.desc = ''
          this.searchcodes = []
          this.$refs.tree.setCheckedKeys([]);
        },
        //IPC分类查询
        async query(){
          this.queryResults({
            filterText: this.filterText,
            form:this.form.resource
          })
          // this.$emit('query',{
          //   filterText: this.filterText,
          //   form:this.form.resource
          // })
        },
        //获取tree的选中项
        handleCheckChange(data, checked, indeterminate) {
          var index = null
          if(checked){
            // this.ids.push()
            this.searchcodes.push(data.searchcode)
          }else {
            index = this.searchcodes.indexOf(data.searchcode);
            if (index > -1) {
              this.searchcodes.splice(index, 1);
            }
          }

          this.ruleForm.desc = this.searchcodes.join(' ')

        },
        filterNode(value, data) {
          if (!value) return true;
          if(this.form.resource == 1){
            return data.searchcode.indexOf(value) !== -1;
          }else if(this.form.resource == 2){
            return data.label.indexOf(value) !== -1;
          }
        },
      },
      watch:{
        ruleForm:{
          handler(){
            let that = this
            this.$nextTick(() => {
              if(that.ruleForm.desc != null) that.$refs.tree.setCheckedKeys(that.ruleForm.desc.split(' '));
            })
          },
          immediate:true,
          deep:true
    },
        dialogTableVisible:{
          handler(newVal){
            let that = this
            if(newVal == true){
              that.$nextTick(() => {
                if(that.advancedIpcClass != null){
                  that.$refs.tree.setCheckedKeys(that.advancedIpcClass.split(' '));
                  // that.searchcodes = that.advancedIpcClass.split(' ')
                  that.ruleForm.desc = that.advancedIpcClass
                }
              })

            }else {
              that.$nextTick(() => {
                that.ruleForm.des = ''
                that.$refs.tree.setCheckedKeys([]);
              })

            }
          },
          immediate:true
        },
        pictLoading(newVal){
          let that = this
            if(newVal == false && that.ruleForm.desc != null)
            that.$refs.tree.setCheckedKeys(that.ruleForm.desc.split(' '));
        }
      }
    }
</script>

<style lang="less">
  .picType{
    .tree {
      height: 350px;
      overflow: auto;
      border: 2px solid #EBEEF5;
      min-width:100%;
      display: inline-block;
      width: 100%;
      /*/deep/.el-tree-node{*/
      min-width:100%;
      display: inline-block;
      /*}*/
    }
  }

  /*.ipcTooltip{*/
    ///*增加tooltip显示宽度*/
    .ipcTooltip {
      max-width: 30%!important;  //宽度可根据自己需要进行设置
    }

    .ipcTooltip.is-light[x-placement^=top] .popper__arrow::after {
      //border-color: #75adf2;
      border-top-color: #e1eeff!important;
    }

    ///*增加tooltip显示框样式*/
    .ipcTooltip.is-light {
      border-color: #75adf2!important;
      background: #e1eeff!important;
      color: @blue!important;
    }

    .ipcTooltip[x-placement^="bottom"] .popper__arrow {
      border-bottom-color: #75adf2 !important;
    }
    .ipcTooltip[x-placement^="bottom"] .popper__arrow::after {
      border-bottom-color: #e1eeff !important; //箭头背景色
    }

    .ipcTooltip.is-light[x-placement^=right] .popper__arrow {
      border-right-color:  #e1eeff !important; //箭头背景色
    }
  /*}*/

</style>
