<template>
  <div  :class="['patentSearch',{'pb70':selectSearchType != 3}]" @click="blurFun">
    <h1 class="h_none">专利检索</h1>
    <h2 class="h_none">蓝灯鱼专利检索,专利,专利检索,专利搜索,专利查询</h2>
    <img src="/images/result_show_logo.png" alt class="h_none" />
    <!-- <div class="container update">专利数据正在更新中。。。</div> -->
    <div class="batch_search_result" v-if="selectSearchType == 4 && result">
      <div class="batch_search_con">
        <span class="left">{{$t(`PS.batch_search_con[0]`)}} <strong>{{batchResult}}</strong>{{$t(`PS.batch_search_con[1]`)}}</span>
        <span class="right" @click="batchExportAjax"> <i> <img src="@/assets/images/patent/icon_batch_export.png"
                                                               alt=""> </i> <b>{{$t(`PS.batch_search_con[2]`)}}</b>
        </span>
      </div>
    </div>
    <div class="container">
      <!-- <div class="status-box"> -->
      <!-- <span @click="serviceNameType == 2 ? toApplyEvent() :''"
        :class="{'active':serviceNameType == 2}">{{serviceNameType === 0 ? $t(`personalCenter.server.try`):serviceNameType === 1 ? $t(`personalCenter.server.apply`):serviceNameType === 2 ? $t(`personalCenter.server.toApplyAbbr`) : '' }}</span>-->
      <!-- </div> -->
      <div class="input_wrap">
        <!-- <div class="fish_bj">
          <img src="@/assets/images/common/fish.png" alt="">
        </div>-->
        <!-- 输入框改版 -->
        <!-- <div :class="['input-single',{'borderBlue':inputFocus}]">
          @click="globalEvent"
          <span></span>
          <div class="input_box">
            <input class="input-con" autofocus v-model="search" @click="focusFun" @keyup.enter="onSearch"
              @focus="inputFocus=true" @blur="inputFocus=false" />
          </div>
          <p class="keyword_btn" @click="keywordlDialogVisible = true">关键词</p>
          <p class="query-btn" @click="onSearch"></p>
        </div>-->
        <div class="input_textarea_wrap">
          <!--          切换标题-->
          <ul class="search_type">
            <li v-for="(item,index) in searchTypeArr" :key="index" :class="{'current_type':item.currentTypeBool}"
                @click="searchTypeEvent(item,index)">{{item.name}}</li>
          </ul>
          <!--          数据库选择-->
          <div class="global_wrap" @click="globalEvent"></div>

          <!--          批量检索-->
          <el-input type="textarea" :class="{'input_textarea_border':inputFocus}" v-if="selectSearchType != 3"
                    :readonly="selectSearchType == 4 && batchFile != ''"
                    :placeholder="selectSearchType == 1 ? $t(`PS.batchFile[0]`) : selectSearchType == 2 ? $t(`PS.batchFile[1]`) : selectSearchType == 4 ? $t(`PS.batchFile[2]`) : ''"
                    v-model="search" resize="none" :autosize="{ minRows: 3}" @click="focusFun" @keyup.enter="onSearch"
                    @focus="inputFocus=true" @blur="inputFocus=false" @input="entering"></el-input>

          <!-- 上一版本 -->
          <!-- <i :class="['patent_switch',{'defaultActive':!isAI}]" @click.stop="switchEvent()">AI</i> -->

          <i class="patent_close" v-if="search != '' && selectSearchType != 3" @click=" closeEvent()">
            <img src="@/assets/images/patent/icon_close.png" mode="widthFix" />
          </i>

          <!--         高级检索-->
          <div class="advanced_search_wrap" v-if="selectSearchType == 3">
            <ul>
              <li>
                <span @click="advancedCommonEvent(
                  {
                    en: $t(`PS.advanced_search_wrap[0]`),
                    cn: '申请号'
                },advancedApplyNum)">{{$t(`PS.advanced_search_wrap[0]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedApplyNum" :placeholder="$t(`PS.advanced_search_wrap[1]`)"
                            clearable></el-input>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                  en: $t(`PS.advanced_search_wrap[2]`),
                  cn: '申请日'
                },advancedApplyDateStart,advancedApplyDateEnd)">{{$t(`PS.advanced_search_wrap[2]`)}}</span>
                <el-date-picker v-model="advancedApplyDateStart" value-format="yyyy-MM-dd" type="date" :placeholder="$t(`PS.advanced_search_wrap[3]`)">
                </el-date-picker>
                <em style="margin:0 5px;line-height:34px;">-</em>
                <el-date-picker v-model="advancedApplyDateEnd" value-format="yyyy-MM-dd" type="date" :placeholder="$t(`PS.advanced_search_wrap[4]`)">
                </el-date-picker>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en: $t(`PS.batchSearchType[1]`),
                cn: '公开号'
                },advancedPubNum)">{{$t(`PS.batchSearchType[1]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedPubNum" :placeholder="$t(`PS.advancedTitle[17]`)" clearable>
                  </el-input>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en: $t(`PS.advanced_search_wrap[6]`),
                cn: '公开日'
                },advancedPubDateStart,advancedPubDateEnd)">{{$t(`PS.advanced_search_wrap[6]`)}}</span>
                <el-date-picker v-model="advancedPubDateStart" value-format="yyyy-MM-dd" type="date" :placeholder="$t(`PS.advanced_search_wrap[3]`)">
                </el-date-picker>
                <em style="margin:0 5px;line-height:34px;">-</em>
                <el-date-picker v-model="advancedPubDateEnd" value-format="yyyy-MM-dd" type="date" :placeholder="$t(`PS.advanced_search_wrap[4]`)">
                </el-date-picker>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en: $t(`PS.advancedTitle[0]`),
                cn: '标题/摘要'
                },advancedTitle)">{{$t(`PS.advancedTitle[0]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedTitle" :placeholder="$t(`PS.advancedTitle[1]`)" clearable>
                  </el-input>
                  <i class="advanced_search_btn" @click="keywordlDialogEvent(advancedTitle,10000)"></i>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en: $t(`PS.advancedTitle[2]`),
                cn: '法律状态'
                },advancedLegalStatus)">{{$t(`PS.advancedTitle[2]`)}}</span>
                <el-select v-model="advancedLegalStatus" :placeholder="$t(`PS.advancedTitle[3]`)">
                  <el-option v-for="(item,index) in advancedLegalArr" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en:$t(`PS.advancedTitle[4]`),
                cn:'申请人'
                },advancedApplicant)">{{$t(`PS.advancedTitle[4]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedApplicant" :placeholder="$t(`PS.advancedTitle[5]`)" clearable>
                  </el-input>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en: $t(`PS.advancedTitle[6]`),
                cn: '发明人'
                },advancedInventor)">{{$t(`PS.advancedTitle[6]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedInventor" :placeholder="$t(`PS.advancedTitle[7]`)" clearable>
                  </el-input>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en:$t(`PS.advancedTitle[8]`),
                cn:'代理机构'
                },advancedAgency)">{{$t(`PS.advancedTitle[8]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedAgency" :placeholder="$t(`PS.advancedTitle[9]`)" clearable>
                  </el-input>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en: $t(`PS.advancedTitle[10]`),
                cn: '代理人'
                },advancedAgent)">{{$t(`PS.advancedTitle[10]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedAgent" :placeholder="$t(`PS.advancedTitle[11]`)" clearable>
                  </el-input>
                </p>
              </li>
              <li>
                <span @click="advancedCommonEvent({
                en:$t(`PS.advancedTitle[12]`),
                cn: 'IPC分类'
                },advancedIpcClass)">{{$t(`PS.advancedTitle[12]`)}}</span>
                <p class="advanced_search_input_wrap" @click.stop>
                  <el-input class="advanced_search_input" v-model="advancedIpcClass" :placeholder="$t(`PS.advancedTitle[13]`)" clearable>
                  </el-input>
                  <i @click="searchBtn()" class="advanced_search_btn ipc_btn"></i>
                </p>
              </li>
            </ul>
            <div class="advanced_textarea_warp">
              <p>
                <span v-for="(item,index) in advancedTextareaArr" :key="index"
                      @click="advancedSymbolEvent(item)">{{item}}</span>
              </p>
              <el-input type="textarea" ref="targetIn" placeholder="" v-model="advancedSearch" resize="none" :autosize="{ minRows: 3}"
                        @click="focusFun" @focus="inputFocus=true" @blur="inputFocus=false"></el-input>
            </div>
          </div>

          <!--          批量检索-->
          <div class="upload_wrap" v-if="selectSearchType == 4">
            <div style="display: flex">
              <div class="uploadClasss">
                <el-popover style="margin-left: 3px"  placement="bottom" trigger="hover" :content="$t(`PS.advancedTitle[14]`) + '：xls'" popper-class="translate_popper">
                  <div class="upload-box" slot="reference">
                    <img class="upload" src="@/assets/images/translation/template_upload.png" alt="" >

                    <a href="/file/蓝灯鱼批量检索-上传文件模版.xlsx"><img class="upload_hover" src="@/assets/images/translation/template_upload_hover.png" alt=""
                    ></a>
                  </div>
                </el-popover>
              </div>

              <div class="uploadClass" style="display: flex;align-items: center">
                <!--              模板下载-->
                <el-upload class="upload_btn" action="" :show-file-list="false" :auto-upload="false"
                           :on-change="uploadChangeFn">
                  <el-popover placement="bottom" trigger="hover" :content="$t(`PS.advancedTitle[15]`) + '：xls'" popper-class="translate_popper">
                    <div class="upload-box" slot="reference">
                      <img class="upload" src="@/assets/images/translation/upload_2.png" alt="" @click="search=''">
                      <img class="upload_hover" src="@/assets/images/translation/upload_2_hover.png" alt=""
                           @click="search=''">
                    </div>
                  </el-popover>
                </el-upload>
                <!--              模板上传-->
                <!--              <el-upload  action="" :show-file-list="false" :auto-upload="false"-->
                <!--                         :on-change="uploadChangeFn">-->
                <!--              </el-upload>-->
              </div>

            </div>

            <template>
              <!--              <el-radio-group v-model="batchSearchType">-->
              <!--                <el-radio :label="1">申请号</el-radio>-->
              <!--                <el-radio :label="2">公开号</el-radio>-->
              <!--              </el-radio-group>-->

              <el-checkbox-group v-model="batchSearchType">
                <el-checkbox label="1">{{$t(`PS.advanced_search_wrap[0]`)}}</el-checkbox>
                <el-checkbox label="2">{{$t(`PS.batchSearchType[1]`)}}</el-checkbox>
              </el-checkbox-group>
            </template>
          </div>

          <!--          批量检索-->
          <strong v-if="selectSearchType == 4" class="batch_hint"><em
            class="el-icon-warning"></em>{{$t(`PS.batchSearchType[2]`)}}</strong>

          <div class="query_btn_wrap">
            <img class="ying" src="../../assets/images/patent/ying.png" alt="">
            <p class="query-btn whitetoBlue" v-if="selectSearchType == 4" @click="closeEvent">{{$t(`PS.batchSearchType[3]`)}}</p>
            <p class="query-btn" @click="onSearch">{{$t(`PS.batchSearchType[4]`)}}</p>
          </div>
          <!-- 上一版本 -->
          <!-- <p v-if="isAI && countC<20 && countC > 0 && spaceLength > 1 " class="ai_hint">
            <i class="el-icon-warning"></i> 输入内容较短，建议切换至非AI模式
          </p> -->
        </div>
        <p class="arrow_btn" :class="{'rotate':addKeywordsShowBool}" @click="addKeywordsShowEvent"
           v-if="selectSearchType == 2"></p>
        <div class="add_keywords_wrap" v-show="addKeywordsShowBool" v-if="selectSearchType == 2">
          <div class="add_keywords_con" v-for="(item,index) in keywordsList" :key="index">
            <el-select class="add_keywords_select" v-model="item.condition" :placeholder="$t(`PS.batchSearchType[5]`)">
              <el-option v-for="(item,index) in conditionArr" :key="index" :label="item" :value="item"></el-option>
            </el-select>
            <el-select class="add_keywords_select" v-model="item.fieldName" :placeholder="$t(`PS.batchSearchType[5]`)"
                       @change="literatureEvent(item,index)">
              <el-option-group v-for="group in literatureArr" :key="group.name" :label="group.name">
                <el-option v-for="item in group.list" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-option-group>
            </el-select>
            <div class="add_keywords_input_wrap" v-show="item.type == 'input_keywords'" @click.stop>
              <el-input class="add_keywords_input add_keywords_title" v-model="item.fieldValue" :placeholder="$t(`PS.batchSearchType[6]`)"
                        clearable></el-input>
              <i class="search_keyword_btn" @click="keywordlDialogEvent(item.fieldValue,index)"></i>
            </div>
            <div class="add_keywords_input_wrap" v-show="item.type == 'input'" @click.stop>
              <el-input class="add_keywords_input" v-model="item.fieldValue" :placeholder="$t(`PS.batchSearchType[6]`)"
                        clearable></el-input>
            </div>
            <el-date-picker class="add_keywords_date" v-show="item.type == 'date'" v-model="item.dateStart"
                            value-format="yyyy-MM-dd" type="date" :placeholder="$t(`PS.advanced_search_wrap[3]`)"></el-date-picker>
            <em style="margin:0 5px;line-height:34px;" v-show="item.type == 'date'">-</em>
            <el-date-picker class="add_keywords_date" v-show="item.type == 'date'" v-model="item.dateEnd"
                            value-format="yyyy-MM-dd" type="date" :placeholder="$t(`PS.advanced_search_wrap[4]`)"></el-date-picker>
            <i :class="['del_hurdles_btn',{'date_hurdles':item.type == 'date'}]"
               @click.stop="(keywordsList.length > 1) ? delHurdlesEvent(index) : ''"></i>
          </div>
          <p class="addHurdlesBtn" @click="addHurdlesEvent">+ {{$t(`PS.batchSearchType[7]`)}}</p>
        </div>
      </div>
    </div>
    <div class="advanced_record" v-if="selectSearchType == 3">
      <div class="advanced_recored_con">
        <h6>{{$t(`PS.advanced_recored_con[7]`)}}</h6>
        <el-table :data="tableData" style="width: 100%" border :row-style="tableRowStyle"
                  :header-cell-style="tableHeaderColor">
          <el-table-column type="index" :label="$t(`PS.advanced_recored_con[0]`)" width="180" align="center">
          </el-table-column>
          <el-table-column prop="patentSearchContents" :label="$t(`PS.advanced_recored_con[1]`)" width="180" align="center">
          </el-table-column>
          <el-table-column prop="country" :label="$t(`PS.advanced_recored_con[2]`)" align="center">
          </el-table-column>
          <el-table-column prop="translateCount" :label="$t(`PS.advanced_recored_con[3]`)" align="center">
          </el-table-column>
          <el-table-column prop="createTime" :label="$t(`PS.advanced_recored_con[4]`)" align="center">
          </el-table-column>
          <el-table-column prop="address" :label="$t(`PS.advanced_recored_con[5]`)" align="center">
            <template slot-scope="scope">
              <!--              <span @click="edit(scope.$index, scope.row)" class="advanced_recored_opera">重命名</span>-->
              <span @click="del(scope.$index, scope.row)" class="advanced_recored_opera">{{$t(`PS.advanced_recored_con[6]`)}}</span>
              <!--              <span @click="retrieval(scope.$index, scope.row)" class="advanced_recored_opera">检索</span>-->
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right;margin-top: 10px">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="current"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>

      </div>
    </div>

    <!-- 登录弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeDialogEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
    <!-- 全球数据弹窗 -->
    <el-dialog :visible.sync="globalDialogVisible" width="500px" custom-class="global_dialog">
      <global :globalData="globalDialogVisible" @globalparamEvent="getGlobalParam"></global>
    </el-dialog>
    <!-- 关键词弹窗 -->
    <el-dialog :visible.sync="keywordlDialogVisible" :close-on-click-modal="false" width="654px"
               custom-class="keyword_dialog">
      <keyword @getKeywordsParam="getKeyword" @getCancelKeyword="getCancel" :keywordData="keywordlDialogVisible">
      </keyword>
    </el-dialog>
    <!--PIC弹出框-->
    <el-dialog title="IPC分类" custom-class="IPCdialog" :close-on-click-modal="false" :visible.sync="dialogTableVisible">
      <picType :dialogTableVisible="dialogTableVisible" :advancedIpcClass="advancedIpcClass"  @picSure="picSureRet"  ></picType>
    </el-dialog>
    <!--表格的编辑-->
    <!--    <el-dialog title="表达式信息" :visible.sync="dialogFormVisible">-->
    <!--      <el-form :model="forms">-->
    <!--        <el-form-item label="名称" :label-width="formLabelWidth">-->
    <!--          <el-input v-model="forms.name" autocomplete="off"></el-input>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="检索式" :label-width="formLabelWidth">-->
    <!--          <el-input :rows="4" style="border: 1px solid #EBEEF5"  type="textarea" v-model="forms.expression"></el-input>-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--      <div slot="footer" class="dialog-footer">-->
    <!--        <el-button @click="expressionCancel()">取 消</el-button>-->
    <!--        <el-button type="primary" @click="expressionSure()">确 定</el-button>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
  // import headers from "@/components/header/headers"
  import merge from 'webpack-merge'
  import addHurdles from "@/components/patentPart/addHurdles";
  import global from "@/components/patentPart/global";
  import keyword from "@/components/patentPart/keyword";
  import login from "@/components/common/login";
  import picType from "../patentPart/picType";
  import { mapState, mapMutations, mapGetters } from "vuex";
  import { exportFile, appNosOrPubNosExcelImport, adSearchLog, delSearchLog, ipcQuery } from "@/request/patentRequest";
  import XLSX from "xlsx"



  export default {
    name: "patentSearch",
    metaInfo: {
      title: "专利检索", // set a title
      meta: [
        {
          // set meta
          name: "keyWords",
          content:
            "蓝灯鱼专利检索，专利，专利检索，专利搜索，专利查询，专利分析，专利数据库，专利下载，专利信息，中国专利，实用新型，发明专利，专利号"
        },
        {
          // set meta
          name: "description",
          content:
            "基于1.2亿全球知识产权数据库，结合人工智能自然语言技术，通过机器学习算法优化排序，提高检索结果的查全率和查准率。提供专利检索、专利分析、专利下载等服务。"
        }
      ]
    },
    //   head () {
    //     return {
    //       title: this.$t(`search.patent.h1`) + " | Lanternfish"
    //     };
    //   },
    data () {
      return {
        //表格的编辑
        dialogFormVisible: false,
        result:false,
        // forms: {
        //   id: '',
        //   name: '',
        //   expression:''
        // },
        formLabelWidth: '60px',
        //el-tree loading
        pictLoading:true,
        dialogTableVisible: false,
        batchSearchType: ['2'],
        inputFocus: false,
        isAI: true, //是否是AI检索
        db: ["CN"], //选择的数据库
        patentType: ["A,B,C", "U,Y"], //选择的专利类型
        conditionArr: ["AND", "OR", "NOT"],
        literatureArr: this.$t(`PS.literatureArr`),
        keywordsList: [
          {
            condition: "AND",
            fieldName: "not_cut_word",
            type: "input_keywords", // input  输入框   date  日期框  input_keywords  关键词输入框
            fieldValue: "",
            dateStart: "",
            dateEnd: ""
          }
        ],
        search: "",
        search1: '',
        serviceNameType: "",
        unLoginBool: false, //判断是否是登录状态,
        dialogVisible: false,
        dialogClose: false,
        //   联想词列表是否展示
        //   relatedWordsBool: false,
        //   联想词数据列表
        relatedWordsList: [],
        //   全球数据弹窗是否展示
        globalDialogVisible: false,
        addKeywordsShowBool: false,
        //   全球选中数据
        globalDataArr: [],
        // 全球选中数据初始化
        initGlobalDataArr: [],
        //   关键词弹窗是否显示
        keywordlDialogVisible: false,
        //   添加关键词对应下标
        keywordsIndex: 0,
        keywordsValue: "",
        //   检索类型
        searchTypeArr: this.$t(`PS.searchTypeArr`),
        selectSearchType: 1,
        advancedApplyNum: null,
        advancedApplyDateStart: null,
        advancedApplyDateEnd: null,
        advancedPubNum: null,
        advancedPubDateStart: null,
        advancedPubDateEnd: null,
        advancedTitle: null,
        advancedLegalArr: this.$t(`PS.advancedLegalArr`),
        advancedLegalStatus: "",
        advancedApplicant: null,
        advancedInventor: null,
        advancedAgency: null,
        advancedAgent: null,
        advancedIpcClass: null,
        advancedTextareaArr: ['AND', 'OR', 'NOT', ' ( ', ' ) '],
        advancedSearch: '',
        advancedSearch_cn: '',
        tableData: [],
        batchResult: 0,
        batchFile: '',
        batchExportFileUrl: '',
        //需要替换的名称
        replace:['申请号','申请日','公开号','公开日','标题/摘要','法律状态','申请人','发明人','代理机构','代理人','IPC分类','公开','有效','无效','审中','全部'],
        //已替换
        replaced:['APNO','APD','PN','PBD','TA','LS','AN','IN','ATC','AT','IPC','review','valid','invalid','default','all'],
        //批量上传
        batchData:[],
        //IPCdata
        IPCdata:[],
        //当前页
        current:1,
        //每页条数
        size:10,
        //分页 总数
        total:0,
        // 获取xlsx批量数据
        batch:[]
      };
    },
    components: {
      login,
      addHurdles,
      global,
      keyword,
      picType
    },
    created() {
      if(this.$route.query.id){
        this.searchTypeEvent(this.searchTypeArr[this.$route.query.id])
      }

    },
    async mounted () {
      //获取高级检索日志
      // this.adSearchLog()

      if( this.tool.getCookie('auth')) {
        this.adSearchLog()
      }else {
        this.tableData = []
        this.total = 0
      }
      // if(this.$store.state.adSearchLogs == undefined){
      //   if( this.tool.getCookie('auth') && localStorage.getItem("adSearchLogs")){
      //     this.adSearchLog()
      //     // this.tableData = JSON.parse(localStorage.getItem("adSearchLogs")).records
      //     // this.total = JSON.parse(localStorage.getItem("adSearchLogs")).total
      //   }else {
      //     this.tableData = []
      //     this.total = 0
      //   }
      // }else {
      //   this.tableData = this.$store.state.adSearchLogs.records
      //   this.total = this.$store.state.adSearchLogs.total
      // }

      if(this.$route.query.id){
        this.searchTypeEvent(this.searchTypeArr[this.$route.query.id])
      }

      if (!this.tool.getCookie("auth")) {
        this.unLoginBool = false;
      } else {


        this.unLoginBool = true;
      }
      localStorage.setItem("beforsearch", '');
      localStorage.setItem("keywordsList", JSON.stringify(this.keywordsList));
      localStorage.setItem("search", this.search);
      localStorage.setItem("isAI", this.isAI);


      if (this.$route.query.dialogVisible != undefined) {
        this.keywordsList = JSON.parse(localStorage.getItem("keywordsList"));
        this.search = localStorage.getItem("search");
        this.isAI = localStorage.getItem("isAI") === "false" ? false : true;
      } else {
        // this.tool.setCookie("country", "");
        // this.tool.setCookie("kinds", "");
        localStorage.setItem("search", "");
        localStorage.setItem("isAI", true);

        if(this.$route.query.patentValue){
          this.search = localStorage.getItem('patentValue')
          localStorage.setItem('patentValue','')
          this.search  ? this.onSearch() : ''
        }

        // if(this.$route.query.languageDirection){
        //   var list = JSON.parse(decodeURIComponent(this.$route.query.languageDirection));
        //   this.leftChange(list,list.id -1)
        //   this.leftText = localStorage.getItem('transValue')
        //   this.translateEvent()
        // }
      }
    },
    methods: {
      ...mapMutations(["setMe", "setNoticeList"]),
      //返回确认值
      picSureRet(res){
        this.advancedIpcClass = res
        this.dialogTableVisible = false
      },
      //查询
      // async queryResults(res){
      //
      //   this.pictLoading = true
      //   let data = []
      //   if(res.filterText){
      //     data = await ipcQuery({
      //       keyword:res.filterText,
      //       searchType:res.form
      //     })
      //
      //     if(data.code == 0){
      //       this.IPCdata = data.data
      //     }
      //   }else {
      //     data = await ipcQuery({
      //       keyword:'',
      //       searchType:''
      //     })
      //
      //     if(data.code == 0){
      //       this.IPCdata = data.data
      //     }
      //   }
      //   this.pictLoading = false
      // },
      //删除
      async del(index,data){
        // let datas = await delSearchLog(data.id)

        let datas= await this.$axios.delete("/lantern/lanlog/delLanLog/"+data.id, {});
        if(datas.data.code == 0){
          //获取高级检索日志
          this.adSearchLog()
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }else {
          this.$message.error('删除失败');
        }
      },

      //分页功能
      handleCurrentChange(val) {
        this.current = val
        this.adSearchLog()
      },
      //获取高级检索
      async adSearchLog(){
        let data = await adSearchLog({
          patentSearchType:'SUPER',
          productType:'专利',
          current: this.current,
          size: this.size
        })
        if(data.code == 0){
          this.tableData = data.data.records
          this.total = data.data.total

          // this.$store.commit('ADSEARCHLOG',data.data.records)
        }
      },

      //el-tree
      loadNode(node, resolve) {
        if (node.level === 0) {
          return resolve([{ name: 'region' }]);
        }
        if (node.level > 1) return resolve([]);

        setTimeout(() => {
          const data = [{
            name: 'leaf',
            leaf: true
          }, {
            name: 'zone'
          }];

          resolve(data);
        }, 500);
      },

      async searchBtn(){
        let data = this.tool.getCookie('auth')
        if(data){
          // let data = await this.$axios.get(`/patent/patentipc/searchPatentIpcs`);
          // if (data.data.code == 0) {
          //   this.IPCdata = data.data.data
          //   this.pictLoading = false
          this.dialogTableVisible = true
          // }
        }else {
          this.dialogVisible = true
        }

      },
      append(data) {
        const newChild = { id: id++, label: 'testtest', children: [] };
        if (!data.children) {
          this.$set(data, 'children', []);
        }
        data.children.push(newChild);
      },

      remove(node, data) {
        // const parent = node.parent;
        // const children = parent.data.children || parent.data;
        // const index = children.findIndex(d => d.id === data.id);
        // children.splice(index, 1);
      },
      // 选择按钮
      btn(){
      },
      // 上传文档
      async uploadChangeFn (file) {
        let _this = this;
        let suffixArr = ["xls", "xlsx"];
        let suffix = file.name.split('.').pop().toLowerCase();
        if (!suffixArr.includes(suffix)) {
          this.$message(this.$t('patentTranslation.errMessage'));
          return;
        }
        this.batchFile = file.raw;

        //显示框展示数据
        this.search = file.name;

        _this.file2Xce(file).then(item => {
          if (item && item.length > 0) {
            // xlsxJson就是解析出来的json数据,数据格式如下
            // [{sheetName: sheet1, sheet: sheetData }]
            if (item[0] && item[0].sheet && item[0].sheet.length) {
              _this.batchData = []
              _this.batchData.push(Object.keys(item[0].sheet[0])[0])

              item[0].sheet.map(items => {
                _this.batchData.push(items[Object.keys(item[0].sheet[0])[0]])
                //显示框展示数据
                // _this.batch = item[0].sheet.map(item => {
                //   return item['专利号']
                // }).join(' ')
              })
              // 删除第一个数据
              _this.batchData.shift()
              // _this.tableData = item[0].sheet  //把数据塞到表格预览
            }
          }
        })
          .catch(error => {
            // loading.close();
          });
      },

      /**
       * 解析文件
       * @param {Object} file
       */
      file2Xce(file) {
        return new Promise(function(resolve, reject) {
          const reader = new FileReader();
          reader.onload = function(e) {
            const data = e.target.result;
            this.wb = XLSX.read(data, {
              type: "binary"
            });
            const result = [];
            this.wb.SheetNames.forEach(sheetName => {
              result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
              });
            });
            resolve(result);
          };
          reader.readAsBinaryString(file.raw);
        });
      },

      // textarea 输入事件
      entering () {
        this.batchFile = '';
        if (this.selectSearchType == 4 && this.batchNumLength > 2000) {
          this.$message('数量不超过2000个');
          return;
        }
      },
      // 批量检索
      async batchSearchAjax () {

        if(this.batchData.length >= 2000){
          this.$message('请输入数量不超过2000个');
          return
        }

        //   batchData
        let search = ''
        //去掉回车换行
        search = this.search.replace(/[\r\n]/g," ");

        search = search.split(',').join(' ')
        search = search.split('，').join(' ')
        //数据传后台
        // const formData = new FormData();
        this.obj = {
          appNoOrPubNos: this.selectSearchType == 4 && this.batchFile != '' ? this.batchData.join(' ') :search,
          // excelFile: this.batchFile,
          appNoSelectStatus: this.batchSearchType.includes('1') ? 1 : 0,
          pubNoSelectStatus: this.batchSearchType.includes('2') ? 1 : 0
        }
        // for (let i in this.obj) {
        //   formData.append(i, this.obj[i]);
        // }
        let {data} = await this.$axios.post(
          '/patent/excel/appNosOrPubNosExcelImport', this.obj,{timeout:180 * 1000}
        )
        if (data.code == 0) {
          this.result = true
          this.batchResult = data.data.size;
          this.batchExportFileUrl = data.data.url;
        }
      },
      // 批量检索结果下载
      batchExportAjax () {
        if(this.batchExportFileUrl){
          let h = this.batchExportFileUrl.substring(this.batchExportFileUrl.lastIndexOf('.') + 1)

          let alink = document.createElement("a");
          // alink.download="teamplte"//文件名,大部分浏览器兼容,IE10及以下不兼容
          alink.href=window.location.origin + this.batchExportFileUrl;//创建 url地址
          alink.download = `蓝灯鱼批量检索结果.${h}`;

          alink.click(); //自动点击
        }
      },
      // 高级搜索点击事件
      advancedCommonEvent (nameParam, valueParam, valueEndParam) {
        if (nameParam.en == '申请日' || nameParam.en == '公开日') {
          if (valueParam != null && valueEndParam != null) {
            this.advancedSearch += ` ${nameParam.en}=(${valueParam}_${valueEndParam})`+ ' ';
            this.advancedSearch_cn += ` ${nameParam.cn}=(${valueParam}_${valueEndParam})`+ ' ';
          } else if (valueParam != null) {
            this.advancedSearch += ` ${nameParam.en}=(${valueParam}_)`+ ' ';
            this.advancedSearch_cn += ` ${nameParam.cn}=(${valueParam}_)`+ ' ';
          } else if (valueEndParam != null) {
            this.advancedSearch += ` ${nameParam.en}=(_${valueEndParam})`+ ' ';
            this.advancedSearch_cn += ` ${nameParam.cn}=(_${valueEndParam})`+ ' ';
          } else {
            this.advancedSearch += ` ${nameParam.en}=( )`;
            this.advancedSearch_cn += ` ${nameParam.cn}=( )`;
          }
        } else {
          if (valueParam != null) {
            this.advancedSearch += ` ${nameParam.en}=(${valueParam})`+ ' ';
            this.advancedSearch_cn += ` ${nameParam.cn}=(${valueParam})`+ ' ';
          } else {
            this.advancedSearch += ` ${nameParam.en}=`+ '( )';
            this.advancedSearch_cn += ` ${nameParam.cn}=`+ '( )';
          }
        }
      },
      // 高级搜索符号点击事件
      advancedSymbolEvent (itemParam) {
        // this.advancedSearch += itemParam;

        //在光标处插入文本
        // 获取el-input中的input元素
        let elInput = this.$refs.targetIn.$el.firstElementChild;
        // 获取el-input的值
        let txt = elInput.value;
        // 做插入前做长度校验（如果有这个需要的话）
        // if (txt.length + value.length > 64) {
        //   return;
        // }
        // 获取选区开始位置
        let startPos = elInput.selectionStart;
        // 获取选区结束位置
        let endPos = elInput.selectionEnd;
        if (startPos === undefined || endPos === undefined) return;

        // 将文本插入光标位置
        this.advancedSearch  = this.advancedSearch.substring(0, startPos) + " " + itemParam + " " + this.advancedSearch.substring(endPos);
        this.advancedSearch_cn  = this.advancedSearch_cn.substring(0, startPos) + " " + itemParam + " " + this.advancedSearch_cn.substring(endPos);
        // 将光标移至文本末尾
        // elInput.focus(0);
        // elInput.setSelectionRange(0,1)

        elInput.selectionStart = startPos + itemParam.length;
        elInput.selectionEnd = startPos + itemParam.length;


      },
      tableHeaderColor ({ row, rowIndex }) {
        return 'background:#d0d3db;color:#333;'
      },
      tableRowStyle ({ row, rowIndex }) {
        return {
          background: '#eaecf0',
          color: '#333',
        }
      },

      // 检索类型切换
      searchTypeEvent (data, dataIndex) {
        this.$router.push({
          query:merge({},{'id':data.type-1})
        })
        this.result = false
        if (data.currentTypeBool) {
          return;
        } else {
          this.searchTypeArr.forEach((item, index) => {
            item.currentTypeBool = false;
          })
          data.currentTypeBool = true;
          this.selectSearchType = data.type;
          this.search = "";
        }
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.init();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeDialogEvent (done) {
        this.dialogClose = true;
        done();
      },
      closeEvent () {
        this.search = "";
        this.batchFile = '';
      },
      switchEvent () {
        this.isAI = !this.isAI;
        localStorage.setItem("isAI", this.isAI);
      },
      // 验证蓝灯鱼用户是否登录
      async getIsLoginAJax () {
        // let data = await this.$axios.post("/patent/search/isLogin");
        let data = this.tool.getCookie('auth')
        if (data) {
          if (this.db.length == 0) {
            this.$message("请至少选择一个数据库");
            return;
          } else {
            // 批量检索
            if (this.selectSearchType == 4) {
              if(!this.search) return
              this.batchSearchAjax();
            } else if (this.selectSearchType == 2) {
              //简单检索
              localStorage.setItem("isAI", false);
              this.keywordsList = JSON.parse(localStorage.getItem("keywordsList"));
              this.search = localStorage.getItem("search");
              if (this.keywordsList != "" || this.keywordsList != null) {
                for (var i = 0; i < this.keywordsList.length; i++) {
                  if (
                    this.keywordsList[i].fieldValue ||
                    this.keywordsList[i].dateStart != "" ||
                    this.keywordsList[i].dateEnd != "" ||
                    this.search != ""
                  ) {
                    this.$router.push({
                      path: "/patentSearch/list"
                    });
                    return;
                  }
                }
              } else {
                this.$router.push({
                  path: "/patentSearch/list"
                });
              }
            }else if(this.selectSearchType == 1){
              //语义检索
              localStorage.setItem("isAI", true);
              this.keywordsList = JSON.parse(localStorage.getItem("keywordsList"));
              this.search = localStorage.getItem("search");
              this.$router.push({
                path: "/patentSearch/list"
              });
              if (this.keywordsList != "" || this.keywordsList != null) {
                for (var i = 0; i < this.keywordsList.length; i++) {
                  if (
                    this.keywordsList[i].fieldValue ||
                    this.keywordsList[i].dateStart != "" ||
                    this.keywordsList[i].dateEnd != "" ||
                    this.search != ""
                  ) {
                    this.$router.push({
                      path: "/patentSearch/list"
                    });
                    return;
                  }
                }
              } else {
                this.$router.push({
                  path: "/patentSearch/list"
                });
              }
            }else {
              this.$router.push({
                path: "/patentSearch/list"
              });
            }
          }
        }
          // else if (data.data.code == 1) {
          //   if (data.data.data.isLogin == "false") {
          //     this.setMe("");
          //     this.setNoticeList("");
          //     this.$message({
          //       message: data.data.msg
          //     });
          //   }
        // }
        else {

          this.dialogVisible = true
        }
      },
      onSearch () {
        let  advancedSearch = ''
        let data = this.tool.getCookie('auth')
        if(data) {

        }else {
          this.dialogVisible = true
          return;
        }
        this.search = this.search.trim()
        localStorage.setItem("kinds", JSON.stringify(this.patentType));
        if(this.selectSearchType == 1 || this.selectSearchType == 2){

          if(!this.search){
            if(this.selectSearchType == 1) return
            else {
              if(this.keywordsList[0].fieldValue != '' && this.keywordsList[0].dateStart != '' && this.keywordsList[0].dateEnd != ''){
                return
              }
            }
          }

          localStorage.setItem("beforsearch", '');
          localStorage.setItem("keywordsList", JSON.stringify(this.keywordsList));
          localStorage.setItem("search", this.search);
          localStorage.setItem("type", 'undefined');
        }else if(this.selectSearchType == 3){
          //高级检索
          if(this.advancedSearch){

            advancedSearch = this.advancedSearch_cn.trim()
            localStorage.setItem("type", '2');
          }else if(this.advancedApplyNum || this.advancedTitle || this.advancedLegalStatus || this.advancedApplicant || this.advancedInventor || this.advancedAgency || this.advancedAgent || this.advancedIpcClass
            || this.advancedApplyDateStart || this.advancedApplyDateEnd || this.advancedPubDateStart || this.advancedPubDateEnd || this.advancedPubNum
          ){
            localStorage.setItem("type", '1');
            //高级检索
            if(this.advancedApplyNum != null && this.advancedApplyNum != '') advancedSearch += `申请号=(${this.advancedApplyNum}) AND `
            if(this.advancedTitle != null && this.advancedTitle != '') advancedSearch += `标题/摘要=(${this.advancedTitle}) AND `
            if(this.advancedLegalStatus != "" && this.advancedLegalStatus != '') advancedSearch += `法律状态=(${this.advancedLegalStatus}) AND `
            if(this.advancedApplicant != null && this.advancedApplicant != '') advancedSearch += `申请人=(${this.advancedApplicant}) AND `
            if(this.advancedInventor != null && this.advancedInventor != '') advancedSearch += `发明人=(${this.advancedInventor}) AND `
            if(this.advancedAgency != null && this.advancedAgency != '') advancedSearch += `代理机构=(${this.advancedAgency}) AND `
            if(this.advancedAgent != null && this.advancedAgent != '') advancedSearch += `代理人=(${this.advancedAgent}) AND `
            if(this.advancedIpcClass != null && this.advancedIpcClass != '') advancedSearch += `IPC分类=(${this.advancedIpcClass}) AND `

            if(this.advancedPubNum != null && this.advancedPubNum != '') advancedSearch += `公开号=(${this.advancedPubNum}) AND `
            if(this.advancedApplyDateStart != null && this.advancedApplyDateEnd !=null && this.advancedApplyDateStart != '' && this.advancedApplyDateEnd !='') advancedSearch += `申请日=(${this.advancedApplyDateStart}_${this.advancedApplyDateEnd}) AND `
            else {
              if(this.advancedApplyDateStart != null && this.advancedApplyDateStart != '') advancedSearch += `申请日=(${this.advancedApplyDateStart}_) AND `
              if(this.advancedApplyDateEnd != null && this.advancedApplyDateEnd != '') advancedSearch += `申请日=(_${this.advancedApplyDateEnd}) AND `
            }

            if(this.advancedPubDateStart  != null && this.advancedPubDateEnd !=null && this.advancedPubDateStart  != '' && this.advancedPubDateEnd !='') advancedSearch += `公开日=(${this.advancedPubDateStart }_${this.advancedPubDateEnd}) AND `
            else {
              if(this.advancedPubDateStart  != null && this.advancedPubDateStart  != '') advancedSearch += `公开日=(${this.advancedPubDateStart }_) AND `
              if(this.advancedPubDateEnd != null && this.advancedPubDateEnd != '') advancedSearch += `公开日=(_${this.advancedPubDateEnd}) AND `
            }

            // if(advancedSearch.length - advancedSearch.lastIndexOf('AND') == 3) {
            advancedSearch = advancedSearch.slice(0, -4)
            // }

          }else {
            return;
          }
          localStorage.setItem("search", advancedSearch);

          for(let i = 0 ; i < this.replace.length ; i++){
            // string.replace(new RegExp(reallyDo, 'g'), replaceWith);
            advancedSearch = advancedSearch.replace(new RegExp(this.replace[i], 'g'), this.replaced[i])
          }
          localStorage.setItem("beforsearch", advancedSearch.trim() );
        }

        //   if (this.isAI) {
        //     if (this.search != "") {
        //       this.getIsLoginAJax();
        //     }
        //   } else {
        this.getIsLoginAJax();
        //   }
      },
      focusFun () {
        if (this.search != "") {
          if (this.db.length == 0) {
            this.$message("请至少选择一个数据库");
            return;
          }
        }
      },
      blurFun (event) { },
      // 点击全球按钮
      globalEvent () {
        this.globalDialogVisible = true;
      },
      // 获取选中全球数据
      getGlobalParam (data) {
        Object.keys(data).forEach(k => {
          if (k == "country") {
            this.db = data[k];
          }
          if (k == "kinds") {
            this.patentType = data[k];
          }
        });
        this.globalDialogVisible = false;
      },
      addKeywordsShowEvent () {
        this.addKeywordsShowBool = !this.addKeywordsShowBool;
      },
      literatureEvent (val, ide) {
        this.literatureArr.forEach((item, index) => {
          item.list.forEach((itm, idx) => {
            if (itm.value == val.fieldName) {
              this.keywordsList[ide].type = item.type;
            }
          });
        });
      },
      // 新增栏位
      addHurdlesEvent () {
        var obj = {
          condition: "AND",
          fieldName: "not_cut_word",
          type: "input_keywords", // input  输入框   date  日期框
          fieldValue: "",
          dateStart: "",
          dateEnd: ""
        };
        this.keywordsList.push(obj);
      },
      // 删除栏位
      delHurdlesEvent (index) {
        this.keywordsList.splice(index, 1);
      },
      // 关键词弹窗显示
      keywordlDialogEvent (valueParam, indexParam) {
        //   this.keywordsValue = valueParam;
        this.keywordsIndex = indexParam;
        this.keywordlDialogVisible = true;
      },
      // 获取关键词
      getKeyword (data) {
        //   this.search = this.search + " 【 " + data.join(" ") + " 】";
        if (this.keywordsIndex == 10000) {
          if(this.advancedTitle != null){
            this.advancedTitle = this.advancedTitle + " " + data.join(" ");
          }else{
            this.advancedTitle = data.join(" ");
          }
        } else {
          this.keywordsList[this.keywordsIndex].fieldValue =
            this.keywordsList[this.keywordsIndex].fieldValue + " " + data.join(" ");
        }
        this.keywordlDialogVisible = false;
      },
      getCancel (data) {
        this.keywordlDialogVisible = data;
      },
      // 空格、逗号、换行转换为逗号分隔的字符串
      getSplitString (str) {
        var arr = str.trim().split(",");
        var resources = "";
        for (var i = 0; i < arr.length; i++) {
          var arr1 = arr[i].split(/\s+/);
          for (var j = 0; j < arr1.length; j++) {
            if (jQuery.trim(arr1[j]) != "") {
              resources += jQuery.trim(arr1[j]) + ",";
            }
          }
        }
        return resources;
      }
    },
    computed: {
      ...mapState({
        // 箭头函数可使代码更简练
        counts: state => state.adSearchLogs,
      }),
      countC () {
        if (!this.search) return 0;
        let arr = this.search.match(
          /([\u2E80-\u2FDF\u3040-\u318F\u31A0-\u31BF\u31F0-\u31FF\u3400-\u4DB5\u4E00-\u9FFF\uA960-\uA97F\uAC00-\uD7FF]{1})|([a-zA-Z0-9]+)/g
        );
        arr = arr || [];
        return arr.length;
      },
      spaceLength () {
        let spaceArr = [];
        if (this.search != "") {
          spaceArr = this.search.trim().split(" ");
        } else {
          spaceArr = [];
        }
        return spaceArr.length;
      },
      batchNumLength () {
        let numArr = [];
        if (this.search != "") {
          numArr = this.getSplitString(this.search).split(",");
        } else {
          numArr = [];
        }
        return numArr.length - 1;
      }
    },
    watch: {
      $route:{
        handler(val,oldval){
          // if(this.$route.query.id) {
          this.searchTypeEvent(this.searchTypeArr[this.$route.query.id])
          // }
        },
        //深度监听
        deep: true
      },
      filterText(val) {
        // this.$refs.tree.filter(val);
      },
      current(news,old){
        this.adSearchLog()
      }
    }
  };
</script>

<style lang='less' scoped>
  /*li{*/
  /*  list-style-type: none;*/
  /*  line-height: 34px;*/
  /*  border-top: 1px solid #EBEEF5;*/
  /*}*/



  .patentSearch {
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }

    .IPCdialog {
      /deep/.el-dialog__body{
        height: 650px;
        overflow:auto;
      }
      span{
        line-height: 15px;
      }
    }

    .btn{
      position: relative;
      right: 500px;
    }

    el-button{
      /deep/.el-button--mini{
        padding: 4px 6px;
      }
    }

    position: absolute;
    min-height: 100%;
    width: 100%;
    background: #f9f9f9;
    box-sizing: border-box;
    &.pb70 {
      padding-bottom: 70px;
    }
    .batch_search_result {
      width: 100%;
      height: 70px;
      line-height: 70px;
      background: @blue;
      position: fixed;
      top: 90px;
      left: 0;
      z-index: 5;
      .batch_search_con {
        display: flex;
        justify-content: space-between;
        width: 1100px;
        margin: 0 auto;
        .left {
          font-size: 18px;
          color: #fff;
          height: 70px;
          strong {
            font-size: 24px;
          }
        }
        .right {
          font-size: 16px;
          color: #fff;
          position: relative;
          padding-left: 40px;
          i {
            display: inline-block;
            width: 26px;
            height: 26px;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -13px;
            img {
              display: block;
              width: 100%;
            }
          }
          b {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .advanced_record {
      width: 100%;
      background: #eaecf0;
      padding: 80px 0;
      margin-top: 150px;
      .advanced_recored_con {
        width: 1200px;
        margin: 0 auto;
        h6 {
          font-size: 16px;
          color: #090909;
          margin-bottom: 25px;
        }
        .advanced_recored_opera {
          color: @blue;
          cursor: pointer;
          padding: 0 5px;
        }
      }
    }
    .container {
      min-height: calc(100vh - 464px);
      padding-top: 130px;
      position: relative;
      max-width: 1200px;
      margin: auto;
      &.update {
        text-align: center;
        margin-top: 200px;
        font-size: 30px;
      }
      .status-box {
        width: 110px;
        height: 109px;
        position: absolute;
        right: 0;
        top: 64px;
        span {
          position: absolute;
          bottom: 25px;
          left: 12px;
          font-size: 14px;
          color: #6c94fa;
          &.active {
            cursor: pointer;
          }
        }
      }
      .fish_bj {
        width: 131px;
        margin: 150px auto -150px;
        img {
          display: block;
          width: 100%;
        }
      }
      .input-single {
        width: 922px;
        height: 46px;
        border-radius: 5px;
        border: solid 1px #c2c6d0;
        display: flex;
        margin: 200px auto 0;
        position: relative;
        span {
          // cursor: pointer;
          display: block;
          width: 65px;
          height: 46px;
          border-radius: 5px 0 0 5px;
          background: #e1e7fb url("@{imgUrl}patent/global.png") no-repeat center;
        }
        .input_box {
          flex: 1;
          padding-left: 22px;
          position: relative;
          border-left: 1px solid #c2c6d0;
          .input-con {
            width: 100%;
            height: 44px;
            border: 0; // 去除未选中状态边框
            outline: none; // 去除选中状态边框
            background-color: rgba(0, 0, 0, 0); // 透明背景
          }
          .related_words {
            border: solid 1px #c2c6d0;
            padding: 25px 25px 15px 25px;
            position: absolute;
            left: -1px;
            z-index: 1;
            background: #fff;
            width: 100%;
            box-sizing: border-box;
            dt {
              display: block;
              font-size: 14px;
              color: #b8bdc6;
              margin-bottom: 22px;
            }
            dd {
              display: inline-block;
              padding: 8px 16px;
              font-size: 14px;
              color: #6c7383;
              background: #e6e9ee;
              border-radius: 4px;
              margin: 0 10px 10px 0;
              cursor: pointer;
              &:hover {
                background: @blue;
                color: #fff;
              }
            }
          }
        }
        .keyword_btn {
          width: 85px;
          line-height: 46px;
          color: #6c7383;
          text-align: center;
          cursor: pointer;
          position: relative;
          &::before {
            content: "";
            width: 1px;
            height: 24px;
            background: #c2c6d0;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -12px;
          }
        }

        .query-btn {
          cursor: pointer;
          width: 78px;
          height: 48px;
          border-radius: 0 5px 5px 0;
          background: @blue url("@{imgUrl}common/search_icon.png") no-repeat
            center / auto 50%;
          margin-top: -1px;
          margin-left: -2px;
          margin-right: -1px;
          &:hover {
            background: @blueHover url("@{imgUrl}common/search_icon.png")
            no-repeat center / auto 50%;
          }
        }
        &.borderBlue {
          border-color: @blue;
          .input_box {
            border-color: @blue;
          }
          .keyword_btn {
            &::before {
              background: @blue;
            }
          }
        }
      }
      .input_textarea_wrap {
        width: 1100px;
        margin: 174px auto 0;
        position: relative;
        .search_type {
          display: flex;
          margin-bottom: -1px;
          li {
            padding: 12px 16px;
            font-size: 16px;
            color: #464f67;
            border: solid 1px transparent;
            cursor: pointer;
            &.current_type {
              color: @blue;
              border: solid 1px #c2c6d0;
              background: #fff;
              border-radius: 2px;
              border-bottom: 0;
            }
          }
        }
        .global_wrap {
          position: absolute;
          top: 9px;
          right: 15px;
          cursor: pointer;
          width: 30px;
          height: 30px;
          background: transparent url("@{imgUrl}patent/global.png") no-repeat
          center;
        }
        .el-textarea {
          width: 100%;
          border: 1px solid #c2c6d0;
          padding: 14px 66px 80px 14px;
          border-radius: 3px;
          box-sizing: border-box;
          margin-bottom: 30px;
          background: #fff;
          &.input_textarea_border {
            border-color: @blue;
          }
        }
        .advanced_search_wrap {
          width: 1100px;
          height: auto;
          border: 1px solid #c2c6d0;
          padding: 30px 40px;
          box-sizing: border-box;
          border-radius: 3px;
          margin: 1px auto 30px;
          background: #fff;
          ul {
            li {
              display: inline-block;
              margin-bottom: 15px;
              span {
                display: inline-block;
                /*width: 80px;*/
                text-align: right;
                margin-right: 10px;
                margin-left: 10px;
                cursor: pointer;
              }
              .advanced_search_input_wrap {
                display: inline-block;
                width: 220px;
                height: 34px;
                line-height: 34px;
                position: relative;
                .advanced_search_btn {
                  display: block;
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  top: 50%;
                  margin-top: -6px;
                  right: 10px;
                  background: #fff url("@{imgUrl}patent/icon_keyword_default.png")
                  no-repeat left center/100% auto;
                  cursor: pointer;
                  &:hover {
                    background: #fff
                    url("@{imgUrl}patent/icon_keyword_active.png") no-repeat
                    left center/100% auto;
                  }
                  &.ipc_btn {
                    background: #fff url("@{imgUrl}patent/icon_add.png") no-repeat
                    left center/100% auto;
                    &:hover {
                      background: #fff url("@{imgUrl}patent/icon_add.png")
                      no-repeat left center/100% auto;
                    }
                  }
                }
              }
            }
          }
          .advanced_textarea_warp {
            border-radius: 5px;
            border: solid 1px #c2c6d0;
            margin-top: 15px;
            p {
              height: 46px;
              background-color: #e6ebf9;
              line-height: 46px;
              border-radius: 5px 5px 0 0;
              span {
                padding: 0 15px;
                cursor: pointer;
              }
            }
            .el-textarea {
              width: 100%;
              border: 1px solid transparent;
              box-sizing: border-box;
              background: #fff;
              padding: 15px;
              margin: 0;
            }
          }
        }

        .patent_switch {
          display: block;
          width: 60px;
          height: 26px;
          line-height: 24px;
          background-color: #8ba4fc;
          border-radius: 50px;
          position: absolute;
          top: 65px;
          right: 14px;
          font-size: 20px;
          color: #fff;
          padding: 0 10px;
          box-sizing: border-box;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            right: 1px;
            top: 1px;
            width: 24px;
            height: 24px;
            border-radius: 200px;
            background: #fff;
          }
          &.defaultActive {
            background: #e4e7f4;
            text-align: right;
            &::before {
              content: "";
              position: absolute;
              left: 1px;
              top: 1px;
              width: 24px;
              height: 24px;
              border-radius: 200px;
              background: #fff;
            }
          }
        }
        .patent_close {
          display: block;
          width: 18px;
          padding-right: 15px;
          position: absolute;
          right: 22px;
          top: 62px;
          cursor: pointer;
          img {
            display: block;
            width: 100%;
          }
        }
        .upload_wrap {
          height: 30px;
          display: flex;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 60px;
          padding-right: 35px;
          box-sizing: border-box;
          .uploadClass {
            font-size: 14px;
            letter-spacing: 0px;
            color: #243c5e;
            margin-left: 15px;
            vertical-align: -6px;
            .upload-box:focus {
              outline: none;
            }
            img {
              width: 26px;
              display: block;
              &.upload_hover {
                display: none;
              }
            }

            &:hover {
              .upload {
                display: none;
              }
              .upload_hover {
                display: inline-block;
              }
            }
          }

          .uploadClasss {
            margin-top: -2px;
            display: flex;
            font-size: 14px;
            letter-spacing: 0px;
            color: #243c5e;
            margin-left: 15px;
            vertical-align: -6px;
            .upload-box:focus {
              outline: none;
            }
            img {
              width: 26px;
              display: block;
              &.upload_hover {
                display: none;
              }
            }

            &:hover {
              .upload {
                display: none;
              }
              .upload_hover {
                display: inline-block;
              }
            }
          }
        }
        .batch_hint {
          font-size: 16px;
          color: #888e9e;
          em {
            color: #acbcf3;
            font-size: 18px;
            margin-right: 8px;
            vertical-align: -1px;
          }
        }
        .query_btn_wrap {
          position: absolute;
          .ying {
            width: 82px;
            height: auto;
            position: absolute;
            top: -26px;
            right: -70px;
          }
          right: 0;
          .query-btn {
            display: inline-block;
            cursor: pointer;
            width: 150px;
            height: 38px;
            border-radius: 6px;
            // background: @blue url("@{imgUrl}common/search_icon.png") no-repeat
            //   center / auto 66%;
            background: @blue;
            line-height: 38px;
            text-align: center;
            color: #fff;
            border: 1px solid @blue;
            &:hover {
              box-shadow: @boxShadow;
            }
            &.whitetoBlue {
              background: #f9f9f9;
              color: @blue;
              margin-right: 30px;
              &:hover {
                box-shadow: none;
                background: @blue;
                color: #fff;
              }
            }
          }
        }
        .ai_hint {
          position: absolute;
          left: 14px;
          bottom: 46px;
          color: #c2c6d0;
          font-size: 14px;
          i {
            font-size: 16px;
            vertical-align: -1px;
          }
        }
      }
      .arrow_btn {
        cursor: pointer;
        width: 14px;
        height: 8px;
        margin: 60px auto 10px;
        background: url("@{imgUrl}patent/up.png") center/100% 100% no-repeat;
        -webkit-transition: -webkit-transform 0.3s ease;
        -moz-transition: -moz-transform 0.3s ease;
        transition: transform 0.3s ease;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
        &:hover {
          background: url("@{imgUrl}patent/up_hover.png") center/100% 100%
          no-repeat;
        }
        &.rotate {
          &:hover {
            background: url("@{imgUrl}patent/up_hover.png") center/100% 100%
            no-repeat;
          }
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }
      .add_keywords_wrap {
        width: 1100px;
        padding: 30px 60px;
        border-radius: 5px;
        margin: 0 auto;
        border: solid 1px #c2c6d0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
        background: #fff;
        .add_keywords_con {
          display: flex;
          margin-bottom: 16px;
          position: relative;
          .del_hurdles_btn {
            display: block;
            width: 26px;
            background: #fff url("@{imgUrl}patent/del_icon.png") no-repeat left
              center/100% auto;
            cursor: pointer;
            margin-left: 11px;
            &:hover {
              background: #fff url("@{imgUrl}patent/del_icon_hover.png") no-repeat
              left center/100% auto;
            }
          }
          .date_hurdles {
            margin-left: 11px;
            width: 26px;
          }
          &:first-child {
            .del_hurdles_btn {
              background: #fff;
              cursor: default;
            }
          }
        }
        .addHurdlesBtn {
          width: 190px;
          height: 34px;
          background: #eaecf1;
          border-radius: 5px;
          font-size: 14px;
          color: #515a71;
          line-height: 34px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
</style>

<style lang="less">

  .patentSearch {
    .add_keywords_wrap {
      .add_keywords_select {
        margin-right: 20px;
        .el-input__inner {
          width: 190px;
          height: 34px;
          line-height: 34px;
        }
      }
      .add_keywords_input_wrap {
        width: 332px;
        position: relative;
        .add_keywords_input {
          height: 34px;
          .el-input__inner {
            height: 34px;
            line-height: 34px;
            box-sizing: border-box;
          }
        }
        .add_keywords_title {
          .el-input__inner {
            padding: 0 50px 0 15px;
            box-sizing: border-box;
          }
          .el-input__suffix {
            right: 30px;
          }
        }
        .search_keyword_btn {
          position: absolute;
          top: 50%;
          margin-top: -8px;
          right: 10px;
          display: block;
          width: 16px;
          height: 16px;
          background: #fff url("@{imgUrl}patent/icon_keyword_default.png")
          no-repeat left center/100% auto;
          cursor: pointer;
          &:hover {
            background: #fff url("@{imgUrl}patent/icon_keyword_active.png")
            no-repeat left center/100% auto;
          }
        }
      }
      .add_keywords_date {
        &.el-input {
          width: 158px;
          height: 34px;
          line-height: 34px;
        }
        .el-input__inner {
          width: 100%;
          height: 100%;
          line-height: 34px;
        }
      }
    }
    .global_dialog {
      position: absolute;
      height: 310px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      margin-top: auto !important;
    }

    .input_textarea_wrap {
      .el-textarea {
        .el-textarea__inner {
          width: 100%;
          border: 1px solid transparent;
          line-height: 1.5;
          letter-spacing: 0px;
          padding: 0px;
        }
      }
    }
    .upload_wrap {
      .el-radio__input.is-checked .el-radio__inner {
        border-color: @blue;
        background: @blue;
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: @blue;
      }
    }
    .advanced_recored_con {
      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper
      .el-table--border.is-scrolling-left
      ~ .el-table__fixed {
        border-right: 1px solid #c6cad4;
      }
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 1px solid #c6cad4;
      }
      .el-table--border,
      .el-table--group {
        border: 1px solid #c6cad4;
        border-bottom: none;
        border-right: none;
      }
      .el-table--border::after,
      .el-table--group::after,
      .el-table::before {
        background-color: #c6cad4;
      }
    }
  }
</style>



